export const COLLECTION_PATHS = {
    SIMPLE_PRODUCTS: "simple_products",
    MAIN_PRODUCTS: "main_products",
    PRODUCTS: "products",
    PROVIDERS: "providers",
    DESTINATIONS: "destinations",
    ORDERS: "orders",
    USERS: "users",
    CUSTOM_PRICE_STRUCTURES: "custom_price_structures"
}
