import PropTypes from "prop-types"
// form
import { useFormContext, Controller } from "react-hook-form"
// @mui
import { Radio, RadioGroup, FormHelperText, FormControlLabel } from "@mui/material"

// ----------------------------------------------------------------------

RHFRadioGroup.propTypes = {
    name: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    getOptionLabel: PropTypes.arrayOf(PropTypes.string),
    getDisabledOptions: PropTypes.arrayOf(PropTypes.bool)
}

export default function RHFRadioGroup({ name, options, getOptionLabel, getDisabledOptions, ...other }) {
    const { control } = useFormContext()

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState: { error } }) => (
                <div>
                    <RadioGroup {...field} row {...other}>
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={getOptionLabel?.length ? getOptionLabel[index] : option}
                                disabled={getDisabledOptions?.length ? getDisabledOptions[index] : false}
                            />
                        ))}
                    </RadioGroup>

                    {!!error && (
                        <FormHelperText error sx={{ px: 2 }}>
                            {error.message}
                        </FormHelperText>
                    )}
                </div>
            )}
        />
    )
}
