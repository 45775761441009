import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
// @mui
import { Box } from "@mui/material"

// ----------------------------------------------------------------------

Logo.propTypes = {
    disabledLink: PropTypes.bool,
    sx: PropTypes.object
}

export default function Logo({ disabledLink = false, sx }) {
    /*
    const theme = useTheme()
    const PRIMARY_LIGHT = theme.palette.primary.light
    const PRIMARY_MAIN = theme.palette.primary.main
    const PRIMARY_DARK = theme.palette.primary.dark
    */

    const logo = (
        <Box sx={{ width: 64, height: 24, ...sx }}>
            <svg width={64} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 32">
                <path
                    d="M72.654 9.504c-6.35 0-11.047 4.785-11.047 11.494 0 6.015 3.958 10.622 10.801 10.622 6.306 0 11.047-4.74 11.047-11.45 0-5.97-4.048-10.666-10.8-10.666zm3.69 18.784c-2.728 1.05-6.351-2.393-8.498-7.939-1.476-3.846-1.32-6.954 1.029-7.849 2.773-1.073 6.373 2.438 8.475 7.894 1.498 3.846 1.342 7-1.006 7.894zM32.917 9.46c-2.728.2-5.233 2.012-6.508 6.26V9.616h-1.408l-7.492 1.722h-7.312V7.022c0-2.684.872-4.204 2.885-4.204 1.7 0 2.84 1.61 3.51 4.047l5.434-2.348C20.82 1.834 17.353.56 13.484.56c-4.696 0-9.638 2.505-9.638 8.9v1.9H.425v1.723h3.421v13.44c0 1.61-.626 2.057-2.549 2.504v2.147h12.321v-2.147c-2.549-.336-3.42-.917-3.42-2.55V13.083H17.374c.134 0 .269.022.403.022.09.023.179.023.268.045 1.52.402 1.99 1.252 1.99 2.504v10.801c0 1.61-.492 2.102-2.504 2.55v2.146h12.321v-2.146c-2.639-.358-3.421-.94-3.421-2.55v-8.631c.715-1.655 2.28-2.796 4.07-2.93 1.409-.09 2.93.492 3.779 1.431l.872-6.664a5.413 5.413 0 00-2.236-.2zM45.283 26.499V9.616h-1.409l-7.491 1.722v1.721l.581.202c1.521.447 1.946 1.163 1.946 2.392V26.5c0 1.61-.582 2.057-2.505 2.505v2.146h11.45v-2.146c-1.99-.425-2.572-.895-2.572-2.505zM58.633 26.499V9.616h-1.386l-7.492 1.722v1.721l.582.202c1.52.447 1.945 1.163 1.945 2.392V26.5c0 1.61-.581 2.057-2.504 2.505v2.146h11.45v-2.146c-2.013-.425-2.595-.895-2.595-2.505zM42.108 7.85c2.08 0 3.578-1.522 3.578-3.601A3.588 3.588 0 0042.108.67a3.588 3.588 0 00-3.578 3.578c0 2.08 1.498 3.6 3.578 3.6zM55.458 7.85c2.08 0 3.578-1.522 3.578-3.601A3.588 3.588 0 0055.458.67a3.588 3.588 0 00-3.578 3.578c0 2.08 1.498 3.6 3.578 3.6z"
                    fill={"#FFF"}
                />
            </svg>
        </Box>
    )

    if (disabledLink) {
        return <>{logo}</>
    }

    return <RouterLink to="/">{logo}</RouterLink>
}
