import { createSlice } from "@reduxjs/toolkit"

// utils
import axios from "../../utils/axios"
//
import { dispatch } from "../store"
import { collection, getDocs, getFirestore, query } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { FIREBASE_API } from "../../config"
import { COLLECTION_PATHS } from "../../utils/firestorePaths"

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    destinations: [],
    destination: null,
    sortBy: null,
    filters: {
        name: "",
        destinationId: ""
    }
}

const slice = createSlice({
    name: "destination",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET DESTINATIONS
        getDestinationsSuccess(state, action) {
            state.isLoading = false
            state.destinations = action.payload.destinations
        },

        // GET DESTINATION
        getDestinationSuccess(state, action) {
            state.isLoading = false
            state.destination = action.payload
        },

        //  SORT & FILTER DESTINATIONS
        sortByDestinations(state, action) {
            state.sortBy = action.payload
        },

        filterDestinations(state, action) {
            state.filters.name = action.payload.name
            state.filters.destinationId = action.payload.destinationId
        }
    }
})

// Reducer
export default slice.reducer

// Actions
export const { sortByDestinations, filterDestinations } = slice.actions

// ----------------------------------------------------------------------

export function getDestinations() {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const firebaseApp = initializeApp(FIREBASE_API)
            const db = getFirestore(firebaseApp)

            const destinationsRef = collection(db, COLLECTION_PATHS.DESTINATIONS)
            const destinationsSnapshot = await getDocs(query(destinationsRef))

            const destinations = []
            destinationsSnapshot.forEach((destination) => {
                const destinationData = destination.data()
                destinations.push({ id: destination.id, ...destinationData })
            })

            dispatch(slice.actions.getDestinationsSuccess({ destinations }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function getDestination(id) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get("/api/destinations/destination", {
                params: { id }
            })
            dispatch(slice.actions.getDestinationSuccess(response.data.product))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
