import { createSlice } from "@reduxjs/toolkit"

// utils
import axios from "../../utils/axios"
//
import { dispatch } from "../store"
import { collection, getDocs, getFirestore, query } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { FIREBASE_API } from "../../config"
import { COLLECTION_PATHS } from "../../utils/firestorePaths"

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    providers: [],
    provider: null,
    sortBy: null,
    filters: {
        name: "",
        destinationId: ""
    }
}

const slice = createSlice({
    name: "provider",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PROVIDERS
        getProvidersSuccess(state, action) {
            state.isLoading = false
            state.providers = action.payload.providers
        },

        // GET PROVIDER
        getProviderSuccess(state, action) {
            state.isLoading = false
            state.provider = action.payload
        },

        //  SORT & FILTER PROVIDERS
        sortByProviders(state, action) {
            state.sortBy = action.payload
        },

        filterProviders(state, action) {
            state.filters.name = action.payload.name
            state.filters.destinationId = action.payload.destinationId
        }
    }
})

// Reducer
export default slice.reducer

// Actions
export const { sortByProviders, filterProviders } = slice.actions

// ----------------------------------------------------------------------

export function getProviders() {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const firebaseApp = initializeApp(FIREBASE_API)
            const db = getFirestore(firebaseApp)

            const providersRef = collection(db, COLLECTION_PATHS.PROVIDERS)
            const providersSnapshot = await getDocs(query(providersRef))

            const providers = []
            providersSnapshot.forEach((provider) => {
                const providerData = provider.data()
                providers.push({ id: provider.id, ...providerData })
            })

            dispatch(slice.actions.getProvidersSuccess({ providers }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function getProvider(id) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get("/api/providers/provider", {
                params: { id }
            })
            dispatch(slice.actions.getProviderSuccess(response.data.product))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
