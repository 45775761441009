import { Suspense, lazy } from "react"
import { Navigate, useRoutes, useLocation } from "react-router-dom"
// layouts
import MainLayout from "../layouts/main"
import DashboardLayout from "../layouts/dashboard"
import LogoOnlyLayout from "../layouts/LogoOnlyLayout"
// guards
import GuestGuard from "../guards/GuestGuard"
import AuthGuard from "../guards/AuthGuard"
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from "../config"
// components
import LoadingScreen from "../components/LoadingScreen"

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation()

    return (
        <Suspense fallback={<LoadingScreen isDashboard={pathname.includes("/dashboard")} />}>
            <Component {...props} />
        </Suspense>
    )
}

export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: "login",
                    element: (
                        <GuestGuard>
                            <Login />
                        </GuestGuard>
                    )
                },
                {
                    path: "register",
                    element: (
                        <GuestGuard>
                            <Register />
                        </GuestGuard>
                    )
                },
                { path: "login-unprotected", element: <Login /> },
                { path: "register-unprotected", element: <Register /> },
                { path: "reset-password", element: <ResetPassword /> },
                { path: "verify", element: <VerifyCode /> }
            ]
        },

        // Dashboard Routes
        {
            path: "dashboard",
            element: (
                <AuthGuard>
                    <DashboardLayout />
                </AuthGuard>
            ),
            children: [
                { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
                /* {
                    path: "feed",
                    element: <Feed />
                }, */
                {
                    path: "feed",
                    children: [
                        { path: "", element: <Feed /> },
                        { path: ":entryTypeFilter", element: <Feed /> },
                        { path: ":entryTypeFilter/:entryId", element: <Feed /> }
                    ]
                },
                {
                    path: "orders",
                    children: [
                        { path: "", element: <OrderList /> },
                        { path: ":orderId", element: <Order /> }
                    ]
                },
                {
                    path: "subscriptions",
                    children: [
                        { path: "", element: <SubscriptionList /> },
                        { path: ":subscriptionId", element: <Subscription /> }
                    ]
                },
                {
                    path: "bookings",
                    children: [
                        { path: "", element: <BookingList /> },
                        { path: ":bookingId", element: <Booking /> }
                    ]
                },
                {
                    path: "customers",
                    children: [
                        { path: "", element: <CustomerList /> },
                        { path: ":customerId", element: <Customer /> }
                    ]
                },
                {
                    path: "products",
                    children: [
                        { path: "", element: <ProductList /> },
                        { path: ":productId", element: <ProductList /> }
                    ]
                },
                {
                    path: "providers",
                    children: [
                        { path: "", element: <ProviderList /> },
                        { path: ":providerId/edit", element: <Provider /> },
                        { path: "new", element: <Provider /> }
                    ]
                },
                {
                    path: "destinations",
                    children: [
                        { path: "", element: <DestinationList /> },
                        { path: ":destinationId/edit", element: <Destination /> },
                        { path: "new", element: <Destination /> }
                    ]
                },
                { path: "start", element: <GeneralApp /> },
                { path: "ecommerce", element: <GeneralEcommerce /> },
                { path: "analytics", element: <GeneralAnalytics /> },
                { path: "banking", element: <GeneralBanking /> },
                { path: "booking", element: <GeneralBooking /> },

                {
                    path: "e-commerce",
                    children: [
                        { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
                        { path: "shop", element: <EcommerceShop /> },
                        { path: "product/:id", element: <EcommerceProductDetails /> },
                        { path: "list", element: <EcommerceProductList /> },
                        { path: "product/new/:template", element: <EcommerceProductCreate /> },
                        { path: "product/new", element: <EcommerceProductCreate /> },
                        { path: "product/:id/edit", element: <EcommerceProductCreate /> },
                        { path: "checkout", element: <EcommerceCheckout /> },
                        { path: "invoice", element: <EcommerceInvoice /> }
                    ]
                },
                {
                    path: "user",
                    children: [
                        { element: <Navigate to="/dashboard/user/profile" replace />, index: true },
                        { path: "profile", element: <UserProfile /> },
                        { path: "cards", element: <UserCards /> },
                        { path: "list", element: <UserList /> },
                        { path: "new", element: <UserCreate /> },
                        { path: ":name/edit", element: <UserCreate /> },
                        { path: "account", element: <UserAccount /> }
                    ]
                },
                {
                    path: "blog",
                    children: [
                        { element: <Navigate to="/dashboard/blog/posts" replace />, index: true },
                        { path: "posts", element: <BlogPosts /> },
                        { path: "post/:title", element: <BlogPost /> },
                        { path: "new-post", element: <BlogNewPost /> }
                    ]
                },
                {
                    path: "mail",
                    children: [
                        { element: <Navigate to="/dashboard/mail/all" replace />, index: true },
                        { path: "label/:customLabel", element: <Mail /> },
                        { path: "label/:customLabel/:mailId", element: <Mail /> },
                        { path: ":systemLabel", element: <Mail /> },
                        { path: ":systemLabel/:mailId", element: <Mail /> }
                    ]
                },
                {
                    path: "chat",
                    children: [
                        { element: <Chat />, index: true },
                        { path: "new", element: <Chat /> },
                        { path: ":conversationKey", element: <Chat /> }
                    ]
                },
                { path: "calendar", element: <Calendar /> },
                { path: "kanban", element: <Kanban /> }
            ]
        },

        // Main Routes
        {
            path: "*",
            element: <LogoOnlyLayout />,
            children: [
                { path: "coming-soon", element: <ComingSoon /> },
                { path: "maintenance", element: <Maintenance /> },
                { path: "pricing", element: <Pricing /> },
                { path: "payment", element: <Payment /> },
                { path: "500", element: <Page500 /> },
                { path: "404", element: <NotFound /> },
                { path: "*", element: <Navigate to="/404" replace /> }
            ]
        },
        {
            path: "/",
            element: <Navigate to="/auth/login" replace />
            /*
            element: <MainLayout />,
            children: [
                { element: <HomePage />, index: true },
                { path: "about-us", element: <About /> },
                { path: "contact-us", element: <Contact /> },
                { path: "faqs", element: <Faqs /> }
            ]
            */
        },
        { path: "*", element: <Navigate to="/404" replace /> }
    ])
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import("../pages/auth/Login")))
const Register = Loadable(lazy(() => import("../pages/auth/Register")))
const ResetPassword = Loadable(lazy(() => import("../pages/auth/ResetPassword")))
const VerifyCode = Loadable(lazy(() => import("../pages/auth/VerifyCode")))

// Dashboard Friio
const OrderList = Loadable(lazy(() => import("../pages/dashboard/FriioOrderList")))
const Order = Loadable(lazy(() => import("../pages/dashboard/FriioOrder")))
const CustomerList = Loadable(lazy(() => import("../pages/dashboard/FriioCustomerList")))
const Customer = Loadable(lazy(() => import("../pages/dashboard/FriioCustomer")))
const SubscriptionList = Loadable(lazy(() => import("../pages/dashboard/FriioSubscriptionList")))
const Subscription = Loadable(lazy(() => import("../pages/dashboard/FriioSubscription")))
const BookingList = Loadable(lazy(() => import("../pages/dashboard/FriioBookingList")))
const Booking = Loadable(lazy(() => import("../pages/dashboard/FriioBooking")))
const ProductList = Loadable(lazy(() => import("../pages/dashboard/FriioProductList")))
const Feed = Loadable(lazy(() => import("../pages/dashboard/FriioFeed")))
const ProviderList = Loadable(lazy(() => import("../pages/dashboard/FriioProviderList")))
const Provider = Loadable(lazy(() => import("../pages/dashboard/FriioProvider")))
const DestinationList = Loadable(lazy(() => import("../pages/dashboard/FriioDestinationList")))
const Destination = Loadable(lazy(() => import("../pages/dashboard/FriioDestination")))

// Dashboard
const GeneralApp = Loadable(lazy(() => import("../pages/dashboard/GeneralApp")))
const GeneralEcommerce = Loadable(lazy(() => import("../pages/dashboard/GeneralEcommerce")))
const GeneralAnalytics = Loadable(lazy(() => import("../pages/dashboard/GeneralAnalytics")))
const GeneralBanking = Loadable(lazy(() => import("../pages/dashboard/GeneralBanking")))
const GeneralBooking = Loadable(lazy(() => import("../pages/dashboard/GeneralBooking")))
const EcommerceShop = Loadable(lazy(() => import("../pages/dashboard/EcommerceShop")))
const EcommerceProductDetails = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductDetails")))
const EcommerceProductList = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductList")))
const EcommerceProductCreate = Loadable(lazy(() => import("../pages/dashboard/EcommerceProductCreate")))
const EcommerceCheckout = Loadable(lazy(() => import("../pages/dashboard/EcommerceCheckout")))
const EcommerceInvoice = Loadable(lazy(() => import("../pages/dashboard/EcommerceInvoice")))
const BlogPosts = Loadable(lazy(() => import("../pages/dashboard/BlogPosts")))
const BlogPost = Loadable(lazy(() => import("../pages/dashboard/BlogPost")))
const BlogNewPost = Loadable(lazy(() => import("../pages/dashboard/BlogNewPost")))
const UserProfile = Loadable(lazy(() => import("../pages/dashboard/UserProfile")))
const UserCards = Loadable(lazy(() => import("../pages/dashboard/UserCards")))
const UserList = Loadable(lazy(() => import("../pages/dashboard/UserList")))
const UserAccount = Loadable(lazy(() => import("../pages/dashboard/UserAccount")))
const UserCreate = Loadable(lazy(() => import("../pages/dashboard/UserCreate")))
const Chat = Loadable(lazy(() => import("../pages/dashboard/Chat")))
const Mail = Loadable(lazy(() => import("../pages/dashboard/Mail")))
const Calendar = Loadable(lazy(() => import("../pages/dashboard/Calendar")))
const Kanban = Loadable(lazy(() => import("../pages/dashboard/Kanban")))

// Main
const HomePage = Loadable(lazy(() => import("../pages/Home")))
const About = Loadable(lazy(() => import("../pages/About")))
const Contact = Loadable(lazy(() => import("../pages/Contact")))
const Faqs = Loadable(lazy(() => import("../pages/Faqs")))
const ComingSoon = Loadable(lazy(() => import("../pages/ComingSoon")))
const Maintenance = Loadable(lazy(() => import("../pages/Maintenance")))
const Pricing = Loadable(lazy(() => import("../pages/Pricing")))
const Payment = Loadable(lazy(() => import("../pages/Payment")))
const Page500 = Loadable(lazy(() => import("../pages/Page500")))
const NotFound = Loadable(lazy(() => import("../pages/Page404")))
