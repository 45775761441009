import PropTypes from "prop-types"
import { createContext, useEffect, useReducer, useState } from "react"
import { initializeApp } from "firebase/app"
import {
    getAuth,
    signOut,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    connectAuthEmulator
} from "firebase/auth"
import { getFirestore, collection, doc, getDoc, setDoc, connectFirestoreEmulator } from "firebase/firestore"
import { connectStorageEmulator, getStorage } from "firebase/storage"

import { FIREBASE_API } from "../config"

// ----------------------------------------------------------------------

const firebaseApp = initializeApp(FIREBASE_API)

const AUTH = getAuth(firebaseApp)

const DB = getFirestore(firebaseApp)

const STORAGE = getStorage(firebaseApp)

// Set to false to use cloud data instead of emulator data
const USE_EMULATOR = true

if (USE_EMULATOR && window.location.hostname === "localhost") {
    console.log("-------")
    console.log("CONNECTING TO EMULATORS")
    connectAuthEmulator(AUTH, "http://0.0.0.0:9099", { disableWarnings: true })
    connectFirestoreEmulator(DB, "0.0.0.0", 8080)
    connectStorageEmulator(STORAGE, "0.0.0.0", 9199)
    console.log("-------")
}

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
}

const reducer = (state, action) => {
    if (action.type === "INITIALISE") {
        const { isAuthenticated, user } = action.payload
        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user
        }
    }

    return state
}

const AuthContext = createContext({
    ...initialState,
    method: "firebase",
    login: () => Promise.resolve(),
    register: () => Promise.resolve(),
    logout: () => Promise.resolve()
})

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
    children: PropTypes.node
}

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState)

    const [profile, setProfile] = useState(null)

    useEffect(
        () =>
            onAuthStateChanged(AUTH, async (user) => {
                if (user) {
                    const userRef = doc(DB, "users", user.uid)
                    const docSnap = await getDoc(userRef)

                    if (docSnap.exists()) {
                        setProfile(docSnap.data())
                    }

                    // Get custom claims for front end view control access
                    try {
                        const idTokenResult = await user.getIdTokenResult()
                        if (idTokenResult.claims.role) {
                            setProfile({ ...profile, role: idTokenResult.claims.role })
                            user.role = idTokenResult.claims.role
                        } else {
                            setProfile({ ...profile, role: null })
                            user.role = null
                        }
                    } catch (error) {
                        console.log(error)
                    }

                    dispatch({
                        type: "INITIALISE",
                        payload: { isAuthenticated: true, user }
                    })
                } else {
                    dispatch({
                        type: "INITIALISE",
                        payload: { isAuthenticated: false, user: null }
                    })
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    )

    const login = (email, password) => signInWithEmailAndPassword(AUTH, email, password)

    const register = (email, password, firstName, lastName) =>
        createUserWithEmailAndPassword(AUTH, email, password).then(async (res) => {
            const userRef = doc(collection(DB, "users"), res.user?.uid)

            await setDoc(userRef, {
                uid: res.user?.uid,
                email,
                displayName: `${firstName} ${lastName}`
            })
        })

    const logout = () => {
        signOut(AUTH)
        dispatch({
            type: "INITIALISE",
            payload: { isAuthenticated: false, user: null }
        })
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "firebase",
                user: {
                    id: state?.user?.uid,
                    email: state?.user?.email,
                    photoURL: state?.user?.photoURL || profile?.photoURL,
                    displayName: state?.user?.displayName || profile?.displayName,
                    role: state?.user?.role || profile?.role || null,
                    phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || "",
                    country: profile?.country || "",
                    address: profile?.address || "",
                    state: profile?.state || "",
                    city: profile?.city || "",
                    zipCode: profile?.zipCode || "",
                    about: profile?.about || "",
                    isPublic: profile?.isPublic || false
                },
                login,
                register,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

export { AuthContext, AuthProvider }
