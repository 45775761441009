import { createSlice } from "@reduxjs/toolkit"
import sum from "lodash/sum"
import uniqBy from "lodash/uniqBy"
// utils
import axios from "../../utils/axios"
//
import { dispatch } from "../store"
import { collection, getDocs, getFirestore, query } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { FIREBASE_API } from "../../config"
import { COLLECTION_PATHS } from "../../utils/firestorePaths"

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    products: [],
    mainProducts: [],
    childProducts: [],
    product: null,
    sortBy: null,
    filters: {
        gender: [],
        category: "All",
        colors: [],
        priceRange: "",
        rating: ""
    },
    checkout: {
        activeStep: 0,
        cart: [],
        subtotal: 0,
        total: 0,
        discount: 0,
        shipping: 0,
        billing: null
    }
}

const slice = createSlice({
    name: "product",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        // GET PRODUCTS
        getProductsSuccess(state, action) {
            state.isLoading = false
            state.products = action.payload.products
            state.mainProducts = action.payload.mainProducts
            state.childProducts = action.payload.childProducts
        },

        // GET PRODUCT
        getProductSuccess(state, action) {
            state.isLoading = false
            state.product = action.payload
        },

        //  SORT & FILTER PRODUCTS
        sortByProducts(state, action) {
            state.sortBy = action.payload
        },

        filterProducts(state, action) {
            state.filters.gender = action.payload.gender
            state.filters.category = action.payload.category
            state.filters.colors = action.payload.colors
            state.filters.priceRange = action.payload.priceRange
            state.filters.rating = action.payload.rating
        },

        // CHECKOUT
        getCart(state, action) {
            const cart = action.payload

            const subtotal = sum(cart.map((cartItem) => cartItem.price * cartItem.quantity))
            const discount = cart.length === 0 ? 0 : state.checkout.discount
            const shipping = cart.length === 0 ? 0 : state.checkout.shipping
            const billing = cart.length === 0 ? null : state.checkout.billing

            state.checkout.cart = cart
            state.checkout.discount = discount
            state.checkout.shipping = shipping
            state.checkout.billing = billing
            state.checkout.subtotal = subtotal
            state.checkout.total = subtotal - discount
        },

        addCart(state, action) {
            const product = action.payload
            const isEmptyCart = state.checkout.cart.length === 0

            if (isEmptyCart) {
                state.checkout.cart = [...state.checkout.cart, product]
            } else {
                state.checkout.cart = state.checkout.cart.map((_product) => {
                    const isExisted = _product.id === product.id
                    if (isExisted) {
                        return {
                            ..._product,
                            quantity: _product.quantity + 1
                        }
                    }
                    return _product
                })
            }
            state.checkout.cart = uniqBy([...state.checkout.cart, product], "id")
        },

        deleteCart(state, action) {
            const updateCart = state.checkout.cart.filter((item) => item.id !== action.payload)

            state.checkout.cart = updateCart
        },

        resetCart(state) {
            state.checkout.activeStep = 0
            state.checkout.cart = []
            state.checkout.total = 0
            state.checkout.subtotal = 0
            state.checkout.discount = 0
            state.checkout.shipping = 0
            state.checkout.billing = null
        },

        onBackStep(state) {
            state.checkout.activeStep -= 1
        },

        onNextStep(state) {
            state.checkout.activeStep += 1
        },

        onGotoStep(state, action) {
            const goToStep = action.payload
            state.checkout.activeStep = goToStep
        },

        increaseQuantity(state, action) {
            const productId = action.payload
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity + 1
                    }
                }
                return product
            })

            state.checkout.cart = updateCart
        },

        decreaseQuantity(state, action) {
            const productId = action.payload
            const updateCart = state.checkout.cart.map((product) => {
                if (product.id === productId) {
                    return {
                        ...product,
                        quantity: product.quantity - 1
                    }
                }
                return product
            })

            state.checkout.cart = updateCart
        },

        createBilling(state, action) {
            state.checkout.billing = action.payload
        },

        applyDiscount(state, action) {
            const discount = action.payload
            state.checkout.discount = discount
            state.checkout.total = state.checkout.subtotal - discount
        },

        applyShipping(state, action) {
            const shipping = action.payload
            state.checkout.shipping = shipping
            state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping
        }
    }
})

// Reducer
export default slice.reducer

// Actions
export const {
    getCart,
    addCart,
    resetCart,
    onGotoStep,
    onBackStep,
    onNextStep,
    deleteCart,
    createBilling,
    applyShipping,
    applyDiscount,
    increaseQuantity,
    decreaseQuantity,
    sortByProducts,
    filterProducts
} = slice.actions

// ----------------------------------------------------------------------

export function getProducts() {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const firebaseApp = initializeApp(FIREBASE_API)
            const db = getFirestore(firebaseApp)

            const providersRef = collection(db, "providers")
            const providersSnapshot = await getDocs(query(providersRef))

            const providers = []
            providersSnapshot.forEach((provider) => {
                const providerData = provider.data()
                providers.push({ id: provider.id, ...providerData })
            })

            const destinationsRef = collection(db, "destinations")
            const destinationsSnapshot = await getDocs(query(destinationsRef))

            const destinations = []
            destinationsSnapshot.forEach((destination) => {
                const destinationData = destination.data()
                destinations.push({ id: destination.id, ...destinationData })
            })

            const products = []
            const querySnapshot = await getDocs(collection(db, COLLECTION_PATHS.SIMPLE_PRODUCTS))
            querySnapshot.forEach((doc) => {
                const productData = doc.data()
                productData.provider = providers.find((provider) => provider.id === productData.providerId)
                productData.destination = destinations.find(
                    (destination) => destination.id === productData.destinationId
                )
                products.push({ id: doc.id, ...productData })
            })

            const mainProducts = []
            const querySnapshot2 = await getDocs(collection(db, COLLECTION_PATHS.MAIN_PRODUCTS))
            querySnapshot2.forEach((doc) => {
                mainProducts.push({ id: doc.id, ...doc.data() })
            })

            const childProducts = []
            const querySnapshot3 = await getDocs(collection(db, COLLECTION_PATHS.PRODUCTS))
            querySnapshot3.forEach((doc) => {
                const productData = doc.data()
                productData.provider = providers.find((provider) => provider.id === productData.providerId)
                productData.mainProduct = mainProducts.find(
                    (mainProduct) => mainProduct.id === productData.mainProductId
                )
                productData.destination = destinations.find(
                    (destination) => destination.id === productData.destinationId
                )
                childProducts.push({ id: doc.id, ...productData })
            })

            dispatch(slice.actions.getProductsSuccess({ products, mainProducts, childProducts }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function getProduct(name) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get("/api/products/product", {
                params: { name }
            })
            dispatch(slice.actions.getProductSuccess(response.data.product))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
