// routes
import { PATH_DASHBOARD } from "../../../routes/paths"
// components
import Label from "../../../components/Label"
import SvgIconStyle from "../../../components/SvgIconStyle"

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />

// const { hostname } = window.location

const isLocal = false // ["localhost"].includes(hostname)
// const isTest = ["test.admin.friio.no", "friio-dev.firebaseapp.com", "friio-dev.web.app"].includes(hostname)
// const isProd = ["admin.friio.no", "friio.firebaseapp.com", "friio.web.app"].includes(hostname)

const ICONS = {
    blog: getIcon("ic_blog"),
    cart: getIcon("ic_cart"),
    chat: getIcon("ic_chat"),
    mail: getIcon("ic_mail"),
    user: getIcon("ic_user"),
    kanban: getIcon("ic_kanban"),
    banking: getIcon("ic_banking"),
    calendar: getIcon("ic_calendar"),
    ecommerce: getIcon("ic_ecommerce"),
    analytics: getIcon("ic_analytics"),
    dashboard: getIcon("ic_dashboard"),
    booking: getIcon("ic_booking")
}

const navConfig = [
    // FRIIO
    // ----------------------------------------------------------------------
    {
        subheader: "",
        items: [
            { title: "start", path: PATH_DASHBOARD.general.start, icon: ICONS.dashboard }
            //{ title: "feed", path: PATH_DASHBOARD.friio.feed, icon: ICONS.kanban }
        ]
    },
    {
        subheader: "Products",
        items: [
            {
                title: "products",
                path: PATH_DASHBOARD.eCommerce.list,
                icon: ICONS.ecommerce
            }
        ]
    },
    {
        subheader: "General",
        items: [
            { title: "orders", path: PATH_DASHBOARD.friio.orders, icon: ICONS.ecommerce },
            { title: "subscriptions", path: PATH_DASHBOARD.friio.subscriptions, icon: ICONS.ecommerce },
            { title: "bookings", path: PATH_DASHBOARD.friio.bookings, icon: ICONS.ecommerce },
            { title: "customers", path: PATH_DASHBOARD.friio.customers, icon: ICONS.user },
            { title: "providers", path: PATH_DASHBOARD.friio.providers, icon: ICONS.user },
            { title: "destinations", path: PATH_DASHBOARD.friio.destinations, icon: ICONS.user }
        ]
    },
    // GENERAL
    // ----------------------------------------------------------------------
    isLocal && {
        subheader: "general",
        items: [
            { title: "e-commerce", path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
            { title: "analytics", path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
            { title: "banking", path: PATH_DASHBOARD.general.banking, icon: ICONS.banking },
            { title: "booking", path: PATH_DASHBOARD.general.booking, icon: ICONS.booking }
        ]
    },
    // MANAGEMENT
    // ----------------------------------------------------------------------
    isLocal && {
        subheader: "management",
        items: [
            // MANAGEMENT : USER
            isLocal && {
                title: "user",
                path: PATH_DASHBOARD.user.root,
                icon: ICONS.user,
                children: [
                    { title: "profile", path: PATH_DASHBOARD.user.profile },
                    { title: "cards", path: PATH_DASHBOARD.user.cards },
                    { title: "list", path: PATH_DASHBOARD.user.list },
                    { title: "create", path: PATH_DASHBOARD.user.newUser },
                    { title: "edit", path: PATH_DASHBOARD.user.editById },
                    { title: "account", path: PATH_DASHBOARD.user.account }
                ]
            },

            // MANAGEMENT : E-COMMERCE
            {
                title: "e-commerce",
                path: PATH_DASHBOARD.eCommerce.root,
                icon: ICONS.cart,
                children: [
                    isLocal && { title: "shop", path: PATH_DASHBOARD.eCommerce.shop },
                    isLocal && { title: "product", path: PATH_DASHBOARD.eCommerce.productById },
                    isLocal && { title: "list", path: PATH_DASHBOARD.eCommerce.list },
                    isLocal && { title: "create", path: PATH_DASHBOARD.eCommerce.newProduct },
                    isLocal && { title: "edit", path: PATH_DASHBOARD.eCommerce.editById },
                    isLocal && { title: "checkout", path: PATH_DASHBOARD.eCommerce.checkout },
                    isLocal && { title: "invoice", path: PATH_DASHBOARD.eCommerce.invoice }
                ]
            },

            // MANAGEMENT : BLOG
            isLocal && {
                title: "blog",
                path: PATH_DASHBOARD.blog.root,
                icon: ICONS.blog,
                children: [
                    { title: "posts", path: PATH_DASHBOARD.blog.posts },
                    { title: "post", path: PATH_DASHBOARD.blog.postById },
                    { title: "new post", path: PATH_DASHBOARD.blog.newPost }
                ]
            }
        ]
    },

    // APP
    // ----------------------------------------------------------------------
    isLocal && {
        subheader: "app",
        items: [
            {
                title: "mail",
                path: PATH_DASHBOARD.mail.root,
                icon: ICONS.mail,
                info: (
                    <Label variant="outlined" color="error">
                        +32
                    </Label>
                )
            },
            { title: "chat", path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
            { title: "calendar", path: PATH_DASHBOARD.calendar, icon: ICONS.calendar },
            {
                title: "kanban",
                path: PATH_DASHBOARD.kanban,
                icon: ICONS.kanban
            }
        ]
    }
]

export default navConfig
