import { createSlice } from "@reduxjs/toolkit"

// utils
import axios from "../../utils/axios"
//
import { dispatch } from "../store"
import { collection, getDocs, getFirestore, query } from "firebase/firestore"
import { initializeApp } from "firebase/app"
import { FIREBASE_API } from "../../config"
import { COLLECTION_PATHS } from "../../utils/firestorePaths"

// ----------------------------------------------------------------------

const initialState = {
    isLoading: false,
    error: null,
    customPriceStructures: [],
    customPriceStructure: null,
    sortBy: null,
    filters: {
        name: ""
    }
}

const slice = createSlice({
    name: "customPriceStructure",
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false
            state.error = action.payload
        },

        getCustomPriceStructuresSuccess(state, action) {
            state.isLoading = false
            state.customPriceStructures = action.payload.customPriceStructures
        },

        getCustomPriceStructureSuccess(state, action) {
            state.isLoading = false
            state.customPriceStructures = action.payload
        },

        //  SORT & FILTER DESTINATIONS
        sortByCustomPriceStructures(state, action) {
            state.sortBy = action.payload
        },

        filterCustomPriceStructures(state, action) {
            state.filters.name = action.payload.name
        }
    }
})

// Reducer
export default slice.reducer

// Actions
export const { sortByCustomPriceStructures, filterCustomPriceStructures } = slice.actions

// ----------------------------------------------------------------------

export function getCustomPriceStructures() {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const firebaseApp = initializeApp(FIREBASE_API)
            const db = getFirestore(firebaseApp)

            const ref = collection(db, COLLECTION_PATHS.CUSTOM_PRICE_STRUCTURES)
            const snapshot = await getDocs(query(ref))

            const customPriceStructures = []
            snapshot.forEach((customPriceStructure) => {
                const customPriceStructureData = customPriceStructure.data()
                customPriceStructures.push({ id: customPriceStructure.id, ...customPriceStructureData })
            })

            dispatch(slice.actions.getCustomPriceStructuresSuccess({ customPriceStructures }))
        } catch (error) {
            dispatch(slice.actions.hasError(error))
        }
    }
}

// ----------------------------------------------------------------------

export function getCustomPriceStructure(id) {
    return async () => {
        dispatch(slice.actions.startLoading())
        try {
            const response = await axios.get("/api/customPriceStructures", {
                params: { id }
            })
            dispatch(slice.actions.getCustomPriceStructureSuccess(response.data.customPriceStructure))
        } catch (error) {
            console.error(error)
            dispatch(slice.actions.hasError(error))
        }
    }
}
