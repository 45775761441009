import PropTypes from "prop-types"
import { Link as RouterLink } from "react-router-dom"
// @mui
import { styled } from "@mui/material/styles"
import { Box, Link, Typography } from "@mui/material"
// hooks
import useAuth from "../../../hooks/useAuth"
// routes
import { PATH_DASHBOARD } from "../../../routes/paths"
// components
import MyAvatar from "../../../components/MyAvatar"

// ----------------------------------------------------------------------

const RootStyle = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 2.5),
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    backgroundColor: theme.palette.grey[500_12],
    transition: theme.transitions.create("opacity", {
        duration: theme.transitions.duration.shorter
    })
}))

// ----------------------------------------------------------------------

NavbarAccount.propTypes = {
    isCollapse: PropTypes.bool
}

export default function NavbarAccount({ isCollapse }) {
    const { user } = useAuth()

    const getPrettyRole = (role) => {
        if (role === "superAdmin") {
            return "Almighty Super Administrator"
        }
        if (role === "admin") {
            return "Administrator"
        }
        if (role === "areaAdmin") {
            return "Area administrator"
        }
        if (role === "provider") {
            return "Service Provider"
        }
        if (role === "customerService") {
            return "Customer Service"
        }
        return role
    }

    return (
        <Link underline="none" color="inherit" component={RouterLink} to={PATH_DASHBOARD.user.account}>
            <RootStyle
                sx={{
                    ...(isCollapse && {
                        bgcolor: "transparent"
                    })
                }}
            >
                <MyAvatar />

                <Box
                    sx={{
                        ml: 2,
                        transition: (theme) =>
                            theme.transitions.create("width", {
                                duration: theme.transitions.duration.shorter
                            }),
                        ...(isCollapse && {
                            ml: 0,
                            width: 0
                        }),
                        display: "flex",
                        flexDirection: "column",
                        width: "100%"
                    }}
                >
                    <Typography
                        variant="subtitle2"
                        noWrap
                        sx={{ textOverflow: "ellipsis", width: "calc(100% - 40px)" }}
                    >
                        {user?.displayName || user?.email}
                    </Typography>
                    <Typography
                        variant="body2"
                        noWrap
                        sx={{ color: "text.secondary", textOverflow: "ellipsis", width: "calc(100% - 40px)" }}
                    >
                        {getPrettyRole(user?.role)}
                    </Typography>
                </Box>
            </RootStyle>
        </Link>
    )
}
